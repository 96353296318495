// export const apiConstants = process.env;


export const apiConstants = {
    // Common variables
    REACT_APP_LOGIN_BY: "manual",
    REACT_APP_DEVICE_TYPE: "web",
    REACT_APP_DEVICE_TOKEN: "123456",
    ERROR_CODE: [1000, 1001, 1002, 1003, 1004, 1005, 1006],

    REACT_APP_GOOGLE_API_KEY: "AIzaSyBwMhGADzGH8vWuzlxkTzL0JrvxVPAHtiY",
    REACT_APP_SETTINGS_URL:"https://freak.fan/admin/api/user/get_settings_json",
    REACT_APP_FIREBASE_API_KEY: "AIzaSyBoHjrDF2ZYTIOoyuVyV4YgIgpx5W46PiM",
    REACT_APP_FIREBASE_AUTH_DOMAIN: "fansclub-295709.firebaseapp.com",
    REACT_APP_FIREBASE_PROJECT_ID: "fansclub-295709",
    REACT_APP_FIREBASE_STORAGE_BUCKET: "fansclub-295709.appspot.com",
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: "36578671417",
    REACT_APP_FIREBASE_APP_ID: "1:36578671417:web:9b3caa70fac5d876bdc6e8",
    REACT_APP_FIREBASE_MEASUREMENT_ID: "G-9TEV8L7EW7",
    REACT_APP_FIREBASE_PUBLIC_KEY:
      "BLUNv9ZktZ9_PdWH4FRgDGvX2AfABbdKIse2sIsO8ipaeEtLe_rZG2lYF8tyPvDLDk08t-8aqO6VQGij_p1t2uQ",
  };
  